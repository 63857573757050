<template>
  <van-nav-bar
    :title="title"
  />
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="margin:15px 0 0 0;padding:15px;background:#fff;">
        <van-tag type="success" size="large">&yen; + {{ Math.abs(item.amount ) }}</van-tag>
        <p style="margin:7px 0 0 0;">
          <small>{{ item.memo }}</small>
        </p>
        <p style="color:#999;margin:7px 0 0 0;">
          <small>{{ item.createTime }}</small>
        </p>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'

export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      userId: Cookies.get('userId')
    })
    const getData = () => {
      post('/fund.list', {
        pageNum: state.page,
        userId: state.userId
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    watch(() => useRoute.query, (to, previous) => {
    })
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData
    }
  }
}
</script>

<style scoped>
</style>
